import commonAct from '@/commonActionHandle.js';
import baseComponent from "@/scripts/baseComponent";
import authApi from '@/api/common/auth';
import { mapState } from "vuex";
import { USER_LOG_STATUS_CODE } from "@/constant/config";
import { debounce } from 'vue-debounce';
const queryString = require('query-string');

export default {

    extends: baseComponent,

    data() {
        return {
            listData: {},
            pagination: {
                querySearch: '',
                username: '',
                action: '',
                orderBy: 'ID',
                directionSort: 'DESC',
                pageIndex: 1,
                pageSize: 100,
                outRowsNumber: 0,
            },
            action: '',
            actionList: [],
        };
    },

    computed: {
        ...mapState({
            listStaff: state => state.staffs,
            listServicePack: state => state.common.listServicePack,
            decentralization: state => state.auth.decentralization,
        }),
    },

    created() {

    },

    mounted() {
        this.getData(this.pagination);
    },

    methods: {

        // Close this pop-up
        closeThisPopup() {
            this.listData = {};
            this.closePopup('system-settings-system-retricted-ip-address');
        },

        // Action
        performAction(param) {
            switch (param.action) {
                case 'refreshList':
                    this.getData(this.pagination);
                    break;
                case 'copyToClipboard':
                    this.copyToClipboard(param.data.inputId, param.data.data);
                    break;
            }
        },

        // Get data
        getData(request) {
            this.feedStatusLoading(0);
            authApi.systemRetrictedIpAddressView(queryString.stringify(request))
                .then((res) => {
                    try {
                        let responseData = res.data;
                        let pagingItem = responseData.pagingItem;
                        this.listData = responseData.data;
                        this.listData.forEach(element => {
                            if (element.ipDetails !== undefined && element.ipDetails !== null && element.ipDetails !== '') {
                                element.ipDetails = JSON.parse(element.ipDetails);
                            }
                        });
                        this.pagination.orderBy = pagingItem.orderBy;
                        this.pagination.directionSort = pagingItem.directionSort;
                        this.pagination.pageIndex = pagingItem.pageIndex;
                        this.pagination.pageSize = pagingItem.pageSize;
                        this.pagination.outRowsNumber = pagingItem.outRowsNumber;
                        this.feedStatusLoading(1);
                    } catch (error) {
                        console.log("getData -> error", error)
                        this.feedStatusLoading(3);
                        // this.$store.dispatch("common/showUnkownError", error, "Error");
                    }
                })
                .catch(error => {
                    try {
                        if (error.name === 'Error') {
                            if (this.$route.path !== '/login') {
                                setTimeout(() => {
                                    location.href = "/login";
                                }, 2000);
                            }
                        } else {
                            switch (error.errorCode) {
                                default:
                                    console.log("getData -> error", error)
                                    // this.$store.dispatch("common/showUnkownError", error, "Error");
                                    break;
                            }
                        }
                    } catch (error) {
                        console.log("getData -> error", error)
                        // this.$store.dispatch("common/showUnkownError", error, "Error");
                    }
                    this.feedStatusLoading(3);
                });
        },

        sortList() {

        },

        // Sort list
        sortCurrentList(element) {
            if (this.pagination.directionSort === 'ASC') {
                this.pagination.directionSort = 'DESC'
            } else {
                this.pagination.directionSort = 'ASC'
            }
            let request = {
                orderBy: element,
                directionSort: this.pagination.directionSort,
                pageIndex: 1,
                pageSize: this.pagination.pageSize,
                outRowsNumber: this.pagination.outRowsNumber,
            };
        },

        // Search list
        searchList() {
            this.pagination.action = this.action.join();
            this.getData(this.pagination);
        },

        // Pagination size change
        handleSizeChange(pageSize) {
            let request = {
                querySearch: this.pagination.querySearch,
                username: this.pagination.username,
                orderBy: this.pagination.orderBy,
                directionSort: this.pagination.directionSort,
                pageIndex: 1,
                pageSize: pageSize,
                action: this.pagination.action,
                outRowsNumber: this.pagination.outRowsNumber,
            };
            this.getData(request);
        },

        // Pagination page change
        handleCurrentChange(element) {
            let request = {
                querySearch: this.pagination.querySearch,
                username: this.pagination.username,
                orderBy: this.pagination.orderBy,
                directionSort: this.pagination.directionSort,
                pageIndex: element,
                pageSize: this.pagination.pageSize,
                action: this.pagination.action,
                outRowsNumber: this.pagination.outRowsNumber,
            };
            this.getData(request);
        },
    }
}